<template>
  <div class="step-content">
    <div class="d-block">
      <div class="title">{{ $t('questions.step_1.title') }}</div>
      <SelectBox @update-val="updateVal"
                 type="radio"
                 ref="selectBox"
                 :check-data="$t('questions.step_1.checkbox')"/>
    </div>
    <div class="d-flex justify-content-center">
      <RestartBtn/>
      <div class="btn mt-0" @click="goTo" :class="{disabled: !selected.length}">{{
          $t('questions.step_1.btnText')
        }}
      </div>
    </div>
  </div>
</template>

<script>
import SelectBox from "../components/SelectBox";
import {updateVal} from "../mixins/update-val";
import AnalyticsService from "../services/analytics-service";

export default {
  name: 'Step_1',
  mixins: [updateVal],
  components: {
    SelectBox
  },
  mounted() {
    AnalyticsService.logCheckInProcessStartedEvent();
  },
  methods: {
    goTo() {
      let path = ''
      if (this.selected === 'No') {
        path = '/positive'
        sessionStorage.setItem('unlock-page', 'true')
      }
      else path = '2'
      this.$router.push(path)
      return path
    }
  }
}
</script>
